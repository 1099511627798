// assets
import {
  BuildOutlined,
  MenuUnfoldOutlined,
  CustomerServiceOutlined,
  FormOutlined,
  RobotOutlined,
  CarOutlined,
  ExperimentOutlined,
  SafetyOutlined,
  CheckCircleOutlined,
  FileSearchOutlined,
  AppstoreAddOutlined,
  TeamOutlined
} from '@ant-design/icons';

// icons
const icons = {
  BuildOutlined,
  MenuUnfoldOutlined,
  CustomerServiceOutlined,
  FormOutlined,
  RobotOutlined,
  CarOutlined,
  ExperimentOutlined,
  SafetyOutlined,
  CheckCircleOutlined,
  FileSearchOutlined,
  AppstoreAddOutlined,
  TeamOutlined
};

// ==============================|| MENU ITEMS - lms ||============================== //
const Lms = {
  id: 'lms',
  title: 'Information',
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: 'lms-baseline-product',
      title: '제품관리',
      type: 'item',
      url: '/lms/baseline/product',
      icon: icons.FormOutlined,
      breadcrumbs: false
    },
    {
      id: 'lms-baseline-mold',
      title: '금형관리',
      type: 'item',
      url: '/lms/baseline/mold',
      icon: icons.RobotOutlined,
      breadcrumbs: false
    },
    {
      id: 'lms-baseline-factoryMachine',
      title: '설비관리',
      type: 'item',
      url: '/lms/baseline/factoryMachine',
      icon: icons.CarOutlined,
      breadcrumbs: false
    },
    {
      id: 'customers',
      title: '고객관리',
      url: '/customer/customerCompany',
      type: 'item',
      icon: icons.CustomerServiceOutlined,
      breadcrumbs: false
    },
    {
      id: 'lms-quality-setting',
      title: '검사관리',
      type: 'collapse',
      icon: icons.SafetyOutlined,
      breadcrumbs: false,
      children: [
        {
          id: 'lms-quality-setting-qualitySetting',
          title: '검사기준 등록',
          type: 'item',
          url: '/lms/baseline/qualitySetting',
          icon: icons.CheckCircleOutlined,
          breadcrumbs: false
        },
        {
          id: 'lms-quality-qualitySetting',
          title: '검사기준 추가',
          type: 'item',
          url: '/mes/qualityStandardSetup',
          icon: icons.FileSearchOutlined,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'lms-baseline',
      title: '기준정보',
      type: 'collapse',
      icon: icons.ExperimentOutlined,
      breadcrumbs: false,
      children: [
        {
          id: 'lms-baseline-factory',
          title: '공장',
          type: 'item',
          url: '/lms/baseline/factory',
          icon: icons.BuildOutlined,
          breadcrumbs: false
        },
        {
          id: 'lms-baseline-factoryLine',
          title: '공정(라인)',
          type: 'item',
          url: '/lms/baseline/factoryLine',
          icon: icons.MenuUnfoldOutlined,
          breadcrumbs: false
        },
        {
          id: 'lms-baseline-worker',
          title: '작업자',
          type: 'item',
          url: '/lms/baseline/worker',
          icon: icons.TeamOutlined,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default Lms;
