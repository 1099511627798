import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import MonitoringRoutes from './MonitoringRoutes';
import MesRoutes from './MesRoutes';
import CustomerRoutes from './CustomerRoutes';
import LmsRoutes from './LmsRoutes';
import EdgeRoutes from './EdgeRoutes';
import Notification from './NotificationRoutes';
import ProfileRoutes from './ProfileRoutes';
import EsgRoutes from './EsgRoutes';
import Bbs from './BbsRoutes';
import Kpi from './KpiRoutes';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing')));
const NewsLanding = Loadable(lazy(() => import('pages/bbs/news/newsLanding')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          element: <PagesLanding />
        },
        {
          path: '/news',
          element: <NewsLanding />
        }
      ]
    },
    LoginRoutes,
    MonitoringRoutes,
    EsgRoutes,
    CustomerRoutes,
    MesRoutes,
    ComponentsRoutes,
    MainRoutes,
    LmsRoutes,
    EdgeRoutes,
    Notification,
    ProfileRoutes,
    Bbs,
    Kpi
  ]);
}
