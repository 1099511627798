import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// lms
const AppBaselineFactoryList = Loadable(lazy(() => import('pages/lms/baseline/factory/list')));
const AppBaselineFactoryCreate = Loadable(lazy(() => import('pages/lms/baseline/factory/create')));
const AppBaselineFactoryEdit = Loadable(lazy(() => import('pages/lms/baseline/factory/edit')));

const AppBaselineFactoryLineList = Loadable(lazy(() => import('pages/lms/baseline/factoryLine/list')));
const AppBaselineFactoryLineCreate = Loadable(lazy(() => import('pages/lms/baseline/factoryLine/create')));
const AppBaselineFactoryLineEdit = Loadable(lazy(() => import('pages/lms/baseline/factoryLine/edit')));

const AppBaselineFactoryMachineList = Loadable(lazy(() => import('pages/lms/baseline/factoryMachine/list')));
const AppBaselineFactoryMachineCreate = Loadable(lazy(() => import('pages/lms/baseline/factoryMachine/create')));
const AppBaselineFactoryMachineEdit = Loadable(lazy(() => import('pages/lms/baseline/factoryMachine/edit')));

// const AppBaselineFactoryMachineCycleList = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineCycle/list')));
// const AppBaselineFactoryMachineCycleCreate = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineCycle/create')));
// const AppBaselineFactoryMachineCycleEdit = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineCycle/edit')));

// const AppBaselineFactoryMachineParameterList = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineParameter/list')));
// const AppBaselineFactoryMachineParameterCreate = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineParameter/create')));
// const AppBaselineFactoryMachineParameterEdit = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineParameter/edit')));

// const AppBaselineFactoryMachineSignalList = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineSignal/list')));
// const AppBaselineFactoryMachineSignalCreate = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineSignal/create')));
// const AppBaselineFactoryMachineSignalEdit = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineSignal/edit')));

// const AppBaselineFactoryMachineAlarmList = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineAlarm/list')));
// const AppBaselineFactoryMachineAlarmCreate = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineAlarm/create')));
// const AppBaselineFactoryMachineAlarmEdit = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineAlarm/edit')));

// const AppBaselineFactoryMachineMeasurementList = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineMeasurement/list')));
// const AppBaselineFactoryMachineMeasurementCreate = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineMeasurement/create')));
// const AppBaselineFactoryMachineMeasurementEdit = Loadable(lazy(() => import('pages/lms/baseline/factoryMachineMeasurement/edit')));

const AppBaselineMoldList = Loadable(lazy(() => import('pages/lms/baseline/mold/list')));
const AppBaselineMoldCreate = Loadable(lazy(() => import('pages/lms/baseline/mold/create')));
const AppBaselineMoldEdit = Loadable(lazy(() => import('pages/lms/baseline/mold/edit')));
const AppBaselineMoldCopy = Loadable(lazy(() => import('pages/lms/baseline/mold/copy')));

const AppBaselineMoldHistoryList = Loadable(lazy(() => import('pages/lms/baseline/moldHistory/list')));
const AppBaselineMoldHistoryCreate = Loadable(lazy(() => import('pages/lms/baseline/moldHistory/create')));
const AppBaselineMoldHistoryEdit = Loadable(lazy(() => import('pages/lms/baseline/moldHistory/edit')));
const AppBaselineMoldHistoryDetails = Loadable(lazy(() => import('pages/lms/baseline/moldHistory/details')));

const AppBaselineMoldShotCountList = Loadable(lazy(() => import('pages/lms/baseline/moldShotCount/list')));
const AppBaselineMoldShotCountCreate = Loadable(lazy(() => import('pages/lms/baseline/moldShotCount/create')));
const AppBaselineMoldShotCountEdit = Loadable(lazy(() => import('pages/lms/baseline/moldShotCount/edit')));

// const AppFacility = Loadable(lazy(() => import('pages/lms/facility/default')));
// const AppInspection = Loadable(lazy(() => import('pages/lms/inspection/default')));
// const AppProduction = Loadable(lazy(() => import('pages/lms/production/default')));
// const AppQuality = Loadable(lazy(() => import('pages/lms/quality/default')));
// const AppAiXai = Loadable(lazy(() => import('pages/lms/aixai/default')));
// const AppAnalysis = Loadable(lazy(() => import('pages/lms/analysis/default')));

const AppMesProductList = Loadable(lazy(() => import('pages/mes/product/list')));
const AppMesProductCreate = Loadable(lazy(() => import('pages/mes/product/create')));
const AppMesProductEdit = Loadable(lazy(() => import('pages/mes/product/edit')));
const AppMesProductDetails = Loadable(lazy(() => import('pages/mes/product/details')));
const AppMesProductCopy = Loadable(lazy(() => import('pages/mes/product/copy')));

const AppMesQualitySettingList = Loadable(lazy(() => import('pages/mes/qualitySetting/list')));
const AppMesQualitySettingCreate = Loadable(lazy(() => import('pages/mes/qualitySetting/create')));
const AppMesQualitySettingCopy = Loadable(lazy(() => import('pages/mes/qualitySetting/copy')));

const AppMesWorkerCreate = Loadable(lazy(() => import('pages/mes/worker/create')));

// lms Routes

const LmsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'lms',
          children: [
            {
              path: 'baseline',
              children: [
                {
                  path: 'factory',
                  element: <AppBaselineFactoryList />
                },
                {
                  path: 'factory_create',
                  element: <AppBaselineFactoryCreate />
                },
                {
                  path: 'factory_edit/:id',
                  element: <AppBaselineFactoryEdit />
                },
                {
                  path: 'factoryLine',
                  element: <AppBaselineFactoryLineList />
                },
                {
                  path: 'factoryLine_create',
                  element: <AppBaselineFactoryLineCreate />
                },
                {
                  path: 'factoryLine_edit/:id',
                  element: <AppBaselineFactoryLineEdit />
                },
                {
                  path: 'factoryMachine',
                  element: <AppBaselineFactoryMachineList />
                },
                {
                  path: 'factoryMachine_create',
                  element: <AppBaselineFactoryMachineCreate />
                },
                {
                  path: 'factoryMachine_edit/:id',
                  element: <AppBaselineFactoryMachineEdit />
                },
                {
                  path: 'mold',
                  element: <AppBaselineMoldList />
                },
                {
                  path: 'mold_create',
                  element: <AppBaselineMoldCreate />
                },
                {
                  path: 'mold_edit/:id',
                  element: <AppBaselineMoldEdit />
                },
                {
                  path: 'mold_copy/:id',
                  element: <AppBaselineMoldCopy />
                },
                {
                  path: 'moldHistory',
                  element: <AppBaselineMoldHistoryList />
                },
                {
                  path: 'moldHistory_create/:id',
                  element: <AppBaselineMoldHistoryCreate />
                },
                {
                  path: 'moldHistory_edit/:id',
                  element: <AppBaselineMoldHistoryEdit />
                },
                {
                  path: 'moldHistory_details/:id',
                  element: <AppBaselineMoldHistoryDetails />
                },
                {
                  path: 'moldShotCount',
                  element: <AppBaselineMoldShotCountList />
                },
                {
                  path: 'moldShotCount_create',
                  element: <AppBaselineMoldShotCountCreate />
                },
                {
                  path: 'moldShotCount_edit/:id',
                  element: <AppBaselineMoldShotCountEdit />
                },
                {
                  path: 'product',
                  element: <AppMesProductList />
                },
                {
                  path: 'product_create',
                  element: <AppMesProductCreate />
                },
                {
                  path: 'product_edit/:id',
                  element: <AppMesProductEdit />
                },
                {
                  path: 'product_details/:id',
                  element: <AppMesProductDetails />
                },
                {
                  path: 'product_copy/:id',
                  element: <AppMesProductCopy />
                },
                {
                  path: 'qualitySetting',
                  element: <AppMesQualitySettingList />
                },
                {
                  path: 'qualitySetting_create/:id',
                  element: <AppMesQualitySettingCreate />
                },
                {
                  path: 'qualitySetting_copy/:id',
                  element: <AppMesQualitySettingCopy />
                },
                {
                  path: 'worker',
                  element: <AppMesWorkerCreate />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default LmsRoutes;
