import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'application/json',
    'x-access-token': token
  }
};

const initialState = {
  workers: [],
  error: null,
  loading: false
};

const slice = createSlice({
  name: 'worker',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getWorkers(state, action) {
      state.workers = action.payload;
      state.loading = false;
    },
    createWorkers(state, action) {
      state.workers.push(action.payload);
      state.loading = false;
    },
    updateWorkers(state, action) {
      state.workers = state.workers.map((worker) => (worker.id === action.payload.id ? { ...worker, ...action.payload } : worker));
      state.loading = false;
    },
    deleteWorkers(state, action) {
      state.workers = state.workers.filter((worker) => worker.id !== action.payload);
      state.loading = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

export const { hasError, getWorkers, createWorkers, updateWorkers, deleteWorkers, setLoading } = slice.actions;

export default slice.reducer;

export function getWorkerList() {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const objData = {
        path: '/worker/list'
      };
      const response = await ApiService.get(objData);
      console.log('response:', response);
      dispatch(getWorkers(response.data.workers || []));
    } catch (error) {
      console.error('Error fetching worker list:', error);
      dispatch(hasError(error.toString()));
    }
  };
}

export function createWorker(newWorker) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/worker/create`, newWorker, config);
      if (response.data.success) {
        dispatch(createWorkers(response.data.worker));
        return { success: true, data: response.data.worker };
      } else {
        dispatch(hasError(response.data.message));
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function updateWorker(updatedWorker) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/worker/update/${updatedWorker.id}`, updatedWorker, config);
      if (response.data.success) {
        dispatch(updateWorkers(response.data.worker));
        return { success: true, data: response.data.worker };
      } else {
        dispatch(hasError(response.data.message));
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function deleteWorker(id) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/worker/delete/${id}`, config);
      if (response.data.success) {
        dispatch(deleteWorkers(id));
        return { success: true };
      } else {
        dispatch(hasError(response.data.message));
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return { success: false, error };
    }
  };
}
