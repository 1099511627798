// assets
import { AppstoreAddOutlined, BarChartOutlined, LineChartOutlined, WarningOutlined } from '@ant-design/icons';

// icons
const icons = {
  AppstoreAddOutlined,
  BarChartOutlined,
  LineChartOutlined,
  WarningOutlined
};

// ==============================|| MENU ITEMS - kpi ||============================== //

const Kpi = {
  id: 'kpi',
  title: 'KPI',
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: 'kpi-production',
      title: '생산',
      type: 'collapse',
      icon: icons.BarChartOutlined,
      breadcrumbs: false,
      children: [
        {
          id: 'hourly-production',
          title: '시간당생산량',
          type: 'item',
          url: '/kpi/production/hourly-production',
          icon: icons.LineChartOutlined,
          breadcrumbs: false
        },
        {
          id: 'model-production',
          title: '제품별생산량',
          type: 'item',
          url: '/kpi/production/model-production',
          icon: icons.LineChartOutlined,
          breadcrumbs: false
        },
        {
          id: 'production-target',
          title: '생산량',
          type: 'item',
          url: '/kpi/production/production-target',
          icon: icons.LineChartOutlined,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'kpi-quality',
      title: '품질',
      type: 'collapse',
      icon: icons.WarningOutlined,
      breadcrumbs: false,
      children: [
        {
          id: 'defect-rate',
          title: '제품별불량',
          type: 'item',
          url: '/kpi/quality/defect-rate',
          icon: icons.WarningOutlined,
          breadcrumbs: false
        },
        {
          id: 'production-ppm',
          title: '불량률',
          type: 'item',
          url: '/kpi/quality/production-ppm',
          icon: icons.WarningOutlined,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default Kpi;
