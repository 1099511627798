import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createInvoice(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateInvoice(state, action) {
      const updatedInvoice = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedInvoice.id ? updatedInvoice : item));
    },
    deleteInvoice(state, action) {
      const invoiceId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== invoiceId);
      state.lists.totalItems -= 1;
    },
    endInvoice(state, action) {
      const invoiceId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== invoiceId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, createInvoice, updateInvoice, deleteInvoice, endInvoice } = slice.actions;

export default slice.reducer;

export function getInvoiceList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoice/list',
        params: { page, pageSize }
      };

      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getInvoiceEndList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoice/endList',
        params: { page, pageSize }
      };

      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getInvoiceInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'productBomItems' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/invoice/invoiceUpload`, data, config);

      if (response.status === 200) {
        dispatch(createInvoice(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getInvoiceUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else {
            data.append(key, NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/invoice/invoiceUpdate`, data, config);
      } else {
        const objData = {
          path: '/invoice/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateInvoice(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getInvoiceDelete(invoiceId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoice/delete',
        data: { id: invoiceId }
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteInvoice(invoiceId));
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getInvoiceEnd(invoiceId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoice/end',
        data: { id: invoiceId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(endInvoice(invoiceId));
        return response.data;
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getInvoiceRestore(invoiceId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoice/restore',
        data: { id: invoiceId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(endInvoice(invoiceId));
        return response.data;
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
