// AuthService.js
import api from './ApiAdaptor';

class AuthService {
  async login(email, password) {
    const response = await api.post('/auth/signin', {
      email,
      password
    });
    return response;
  }

  async register(name, email, compName, compNumber, password, confirmPassword) {
    try {
      const response = await api.post('/auth/signup', {
        name,
        email,
        compName,
        compNumber,
        password,
        confirmPassword
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async resetPassword(email) {
    try {
      const response = await api.post('/auth/resetPassword', {
        email
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async getProfile(objData) {
    const params = { params: objData };
    try {
      const response = await api.get('/auth/getProfile', params);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async updateProfile(objData) {
    try {
      const response = await api.post('/auth/updateProfile', objData);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async changePassword(objData) {
    try {
      const response = await api.post('/auth/changePassword', objData);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async logout() {
    try {
      const response = await api.post('/auth/signout');
      return response;
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  }
}

export default new AuthService();
