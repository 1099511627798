import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null,
  alertPopup: false
};

const slice = createSlice({
  name: 'moldHistory',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },

    createMoldHistory(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateMoldHistory(state, action) {
      const updatedMoldHistory = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedMoldHistory.id ? updatedMoldHistory : item));
    },
    deleteMoldHistory(state, action) {
      const moldHistoryId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== moldHistoryId);
      state.lists.totalItems -= 1;
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export const { getLists, createMoldHistory, updateMoldHistory, deleteMoldHistory, alertPopupToggle } = slice.actions;

export default slice.reducer;

export function getMoldHistoryList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/moldHistory/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      // console.log('response:', response);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getMoldHistoryInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();

      Object.keys(NewLists).forEach((key) => {
        if (NewLists[key] != null) {
          if (typeof NewLists[key] === 'object' && !(NewLists[key] instanceof File)) {
            // 객체나 배열인 경우 JSON 문자열로 변환
            data.append(key, JSON.stringify(NewLists[key]));
          } else {
            // 그 외의 경우 (문자열, 숫자, 불리언, File 객체 등)
            data.append(key, NewLists[key]);
          }
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/moldHistory/moldHistoryUpload`, data, config);

      if (response.status === 200) {
        dispatch(createMoldHistory(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

// export function getMoldHistoryInsert(NewLists) {
//   return async (dispatch) => {
//     try {
//       const data = new FormData();

//       Object.keys(NewLists).forEach((key) => {
//         if (['moldGradeArray', 'addedFiles'].includes(key) && Array.isArray(NewLists[key])) {
//           data.append(key, JSON.stringify(NewLists[key]));
//         } else if (NewLists[key] != null) {
//           data.append(key, NewLists[key]);
//         }
//       });

//       // Object.keys(NewLists).forEach((key) => {
//       //   if (key === 'moldGradeArray') {
//       //     data.append(key, JSON.stringify(NewLists[key]));
//       //   } else if (key === 'files' && NewLists[key] && NewLists[key][0]) {
//       //     data.append('file', NewLists[key][0]);
//       //   } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
//       //     data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
//       //   }
//       // });

//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/moldHistory/moldHistoryUpload`, data, config);

//       if (response.status === 200) {
//         dispatch(createMoldHistory(response.data));
//         return { success: true, data: response.data };
//       } else {
//         return { success: false, message: response.data.message };
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.toString()));
//       return { success: false, error };
//     }
//   };
// }

export function getMoldHistoryUpdate(NewLists) {
  console.log('NewLists:', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (NewLists[key] != null) {
          if (typeof NewLists[key] === 'object' && !(NewLists[key] instanceof File)) {
            // 객체나 배열인 경우 JSON 문자열로 변환
            data.append(key, JSON.stringify(NewLists[key]));
          } else {
            // 그 외의 경우 (문자열, 숫자, 불리언, File 객체 등)
            data.append(key, NewLists[key]);
          }
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/moldHistory/moldHistoryUpdate`, data, config);

      console.log('API response data:', response);

      if (response.status === 200 && response.data && response.data.success) {
        dispatch(updateMoldHistory(response.data));
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error: error.message || 'An unknown error occurred' };
    }
  };
}

export function getMoldHistoryDelete(moldHistoryId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/moldHistory/delete',
        data: { id: moldHistoryId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteMoldHistory(moldHistoryId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
