import ApiService from 'services/ApiService';
import { createSlice } from '@reduxjs/toolkit';

// const token = window.localStorage.getItem('serviceToken');
// const config = {
//   headers: {
//     'content-type': 'multipart/form-data',
//     'x-access-token': token
//   }
// };

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'diecastingInfoToDayTime',
  initialState,
  reducers: {
    getLists(state, action) {
      state.lists = action.payload;
    },

    hasError(state, action) {
      state.error = action.payload;
    }
  }
});

export const { getLists } = slice.actions;

export default slice.reducer;

export function getDiecastingInfoToDayTimeList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/diecastingInfoTime/toDayTimeList' });
      console.log('response', response.data);
      dispatch(slice.actions.getLists(response.data));
      console.log(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
