import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: [],
  error: null,
  list: null
};

const slice = createSlice({
  name: 'qna',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    createQna(state, action) {
      state.lists.push(action.payload);
    },
    updateQna(state, action) {
      const updatedQna = action.payload;
      const index = state.lists.findIndex((item) => item.id === updatedQna.id);
      if (index !== -1) {
        state.lists[index] = updatedQna;
      }
    },
    deleteQna(state, action) {
      state.lists = state.lists.filter((item) => item.id !== action.payload);
    }
  }
});

export const { getLists, createQna, updateQna, deleteQna } = slice.actions;
export default slice.reducer;

export function getQnaList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/qna/list' });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getQnaInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      const folder = 'qna';

      // 기본 데이터 처리
      Object.keys(NewLists).forEach((key) => {
        if (['addedFiles'].includes(key) && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] != null) {
          data.append(key, NewLists[key]);
        }
      });

      // 폴더 정보 추가
      data.append('folder', folder);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qna/qnaUpload`, data, config);

      if (response.status === 200 && response.data && response.data.success) {
        dispatch(slice.actions.createQna(response.data.data));
        return { success: true, data: response.data.data };
      } else {
        // console.error('API request failed:', response.data.message);
        return { success: false, message: response.data.message || 'Unknown error occurred' };
      }
    } catch (error) {
      // console.error('Error in getQualityInsert:', error);
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error: error.message || 'An unknown error occurred' };
    }
  };
}

export function getQnaUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();

      Object.keys(NewLists).forEach((key) => {
        if (['addedFiles', 'deletedFiles'].includes(key) && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] != null) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qna/qnaUpdate`, data, config);

      console.log('API response data:', response);

      if (response.status === 200 && response.data && response.data.success) {
        dispatch(slice.actions.updateQna(response.data));
        return { success: true, data: response.data.data };
      } else {
        console.log('Update failed. Response:', response);
        return { success: false, message: response.data.message || 'Update failed' };
      }
    } catch (error) {
      console.error('Error in getQualityUpdate:', error);
      console.error('Error response:', error.response);
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error: error.message || 'An error occurred' };
    }
  };
}

export function getQnaDelete(qnaId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/qna/delete',
        data: { id: qnaId }
      };

      console.log('Deleting QnA:', objData);

      const response = await ApiService.post(objData);

      console.log('Delete response:', response);

      if (response.status === 200) {
        dispatch(deleteQna(qnaId));
        return response.data;
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
