import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'application/json',
    'x-access-token': token
  }
};

const initialState = {
  items: [],
  error: null,
  loading: false
};

const slice = createSlice({
  name: 'materialInspectionItem',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getItems(state, action) {
      state.items = action.payload;
      state.loading = false;
    },
    createItem(state, action) {
      state.items.push(action.payload);
      state.loading = false;
    },
    updateItem(state, action) {
      state.items = state.items.map((item) => (item.id === action.payload.id ? { ...item, ...action.payload } : item));
      state.loading = false;
    },
    deleteItem(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload);
      state.loading = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    }
  }
});

export const { hasError, getItems, createItem, updateItem, deleteItem, setLoading } = slice.actions;

export default slice.reducer;

export function getInspectionItemList() {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const objData = {
        path: '/materialInspectionItem/list'
      };
      const response = await ApiService.get(objData);
      console.log('response:', response);
      dispatch(getItems(response.data.items || []));
    } catch (error) {
      console.error('Error fetching inspection item list:', error);
      dispatch(hasError(error.toString()));
    }
  };
}

export function createInspectionItem(newItem) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/materialInspectionItem/create`, newItem, config);
      if (response.data.success) {
        dispatch(createItem(response.data.item));
        return { success: true, data: response.data.item };
      } else {
        dispatch(hasError(response.data.message));
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function updateInspectionItem(updatedItem) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/materialInspectionItem/update/${updatedItem.id}`,
        updatedItem,
        config
      );
      if (response.data.success) {
        dispatch(updateItem(response.data.item));
        return { success: true, data: response.data.item };
      } else {
        dispatch(hasError(response.data.message));
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function deleteInspectionItem(id) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/materialInspectionItem/delete/${id}`, config);
      if (response.data.success) {
        dispatch(deleteItem(id));
        return { success: true };
      } else {
        dispatch(hasError(response.data.message));
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(hasError(error.toString()));
      return { success: false, error };
    }
  };
}
