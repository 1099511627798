// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import menu from './menu';
import snackbar from './snackbar';
import product from './product';
import cartReducer from './cart';
import invoice from './invoice';
import invoiceList from './invoiceList';
import inventory from './inventory';
import shipment from './shipment';
import material from './material';
import materialHistory from './materialHistory';
import power from './power';
import factory from './factory';
import factoryLine from './factoryLine';
import factoryMachine from './factoryMachine';
import customerCompany from './customerCompany';
import customerManager from './customerManager';
import diecasting from './diecasting';
import diecastingInfo from './diecastingInfo';
import diecastingInfoTime from './diecastingInfoTime';
import diecastingAlm from './diecastingAlm';
import diecastingAlmTable from './diecastingAlmTable';
import diecastingInfoToDayTime from './diecastingInfoToDayTime';
import powerApiInfo from './powerApiInfo';
import powerApiData from './powerApiData';
import dashboard from './dashboard';
import powerWeekDashboard from './powerWeekDashboard';
import versionInfo from './versionInfo';
import contactUs from './contactUs';
import qnaReducer from './qna';
import qnaComments from './qnaComments';
import news from './news';
import userSetup from './userSetup';
import company from './company';
import productionPlan from './productionPlan';
import apiKeys from './apiKeys';
import purchases from './purchases';
import emissionReductionEffort from './emissionReductionEffort';
import productionPlanOutput from './productionPlanOutput';
import materialPlanOut from './materialPlanOut';
import hwaseongTech from './hwaseongTech';
import hwaseongTechInfo from './hwaseongTechInfo';
import factoryMachinePlcAddressFilters from './factoryMachinePlcAddressFilters';
import factoryMachinePlcAddressTexts from './factoryMachinePlcAddressTexts';
import mold from './mold';
import moldHistory from './moldHistory';
import meltingFurnaceLast from './meltingFurnaceLast';
import quality from './quality';
import qualitative from './qualitative';
import qualitativeStandard from './qualitativeStandard';
import quantitative from './quantitative';
import quantitativeStandard from './quantitativeStandard';
import productionCarbon from './productionCarbon';
import moldShotCount from './moldShotCount';

import materialInspectionItem from './materialInspectionItem';
import worker from './worker';
// 파일 업로드 리듀서 import
import multiFilesUpload from './multiFilesUpload';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'mantis-js-'
    },
    cartReducer
  ),
  multiFilesUpload,
  product,
  invoice,
  invoiceList,
  inventory,
  shipment,
  material,
  materialHistory,
  power,
  factory,
  factoryLine,
  factoryMachine,
  customerCompany,
  customerManager,
  diecasting,
  diecastingInfo,
  diecastingInfoTime,
  diecastingAlm,
  diecastingAlmTable,
  diecastingInfoToDayTime,
  powerApiInfo,
  powerApiData,
  dashboard,
  powerWeekDashboard,
  versionInfo,
  contactUs,
  qna: qnaReducer,
  qnaComments,
  news,
  userSetup: userSetup,
  company,
  apiKeys,
  productionPlan,
  productionPlanOutput,
  purchases,
  emissionReductionEffort,
  materialPlanOut,
  hwaseongTech,
  hwaseongTechInfo,
  factoryMachinePlcAddressFilters,
  factoryMachinePlcAddressTexts,
  mold,
  moldHistory,
  meltingFurnaceLast,
  quality,
  qualitative,
  qualitativeStandard,
  quantitative,
  quantitativeStandard,
  productionCarbon,
  moldShotCount,
  materialInspectionItem,
  worker
});

export default reducers;
