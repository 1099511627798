import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // createShipment(state, action) {
    //   state.lists.items = [...state.lists.items, action.payload];
    //   state.lists.totalItems += 1;
    // },
    createShipment(state, action) {
      if (!Array.isArray(state.lists.items)) {
        state.lists.items = [];
      }
      state.lists.items.push(action.payload);
      state.lists.totalItems += 1;
    },
    updateShipment(state, action) {
      const updatedShipment = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedShipment.id ? updatedShipment : item));
    },
    deleteShipment(state, action) {
      const shipmentId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== shipmentId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, getSingleList, createShipment, updateShipment, deleteShipment } = slice.actions;

export default slice.reducer;

export function getShipmentList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/shipment/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      // console.log('response', response);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getShipmentHistoryList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/shipment/historyList',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      // console.log('response', response);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getShipmentInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/shipment/shipmentUpload`, data, config);
      if (response.status === 200) {
        console.log('response', response);
        // 서버 응답에서 필요한 데이터만 추출하여 디스패치
        const shipmentData = response.data.data || response.data;
        dispatch(createShipment(shipmentData));
        return { success: true, data: shipmentData };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Error in getShipmentInsert:', error);
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getShipmentAllInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'plans' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/shipment/shipmentAllUpload`, data, config);
      if (response.status === 200) {
        console.log('response', response);
        // 서버 응답에서 필요한 데이터만 추출하여 디스패치
        const shipmentData = response.data.data || response.data;
        dispatch(createShipment(shipmentData));
        return { success: true, data: shipmentData };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Error in getShipmentInsert:', error);
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getShipmentSingleList(shipmentId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/shipment/list/single', { id: shipmentId });
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getShipmentUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/shipment/shipmentUpdate`, data, config);

      if (response.status === 200) {
        dispatch(updateShipment(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getShipmentAllUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key] && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (key === 'plans' && Array.isArray(NewLists[key])) {
            data.append(key, JSON.stringify(NewLists[key]));
          } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
            data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/shipment/shipmentAllUpdate`, data, config);
      } else {
        const objData = {
          path: '/shipment/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateShipment(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getShipmentDelete(shipmentId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/shipment/delete',
        data: { id: shipmentId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteShipment(shipmentId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getShipmentAllDelete(shipmentId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/shipment/allDelete',
        data: { id: shipmentId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteShipment(shipmentId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
