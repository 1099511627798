import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// Hourly Production Pages
const HourlyProduction = Loadable(lazy(() => import('pages/kpi/production/hourly-production')));
const ModelProduction = Loadable(lazy(() => import('pages/kpi/production/model-production')));
const ProductionTarget = Loadable(lazy(() => import('pages/kpi/production/production-target')));

// Defect Rate Pages
const DefectRate = Loadable(lazy(() => import('pages/kpi/quality/defect-rate')));
const ProductionPpm = Loadable(lazy(() => import('pages/kpi/quality/production-ppm')));

// kpi Routes
const KpiRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'kpi',
          children: [
            {
              path: 'production',
              children: [
                {
                  path: 'hourly-production',
                  element: <HourlyProduction />
                },
                {
                  path: 'model-production',
                  element: <ModelProduction />
                },
                {
                  path: 'production-target',
                  element: <ProductionTarget />
                }
              ]
            },
            {
              path: 'quality',
              children: [
                {
                  path: 'defect-rate',
                  element: <DefectRate />
                },
                {
                  path: 'production-ppm',
                  element: <ProductionPpm />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default KpiRoutes;
