// mes
import mes from './mes';

// lms
import lms from './lms';

// esg
import esg from './esg';

// kpi
import kpi from './kpi';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [mes, lms, esg, kpi]
};

export default menuItems;
