// JWTContext.js
// import PropTypes from 'prop-types';
// import { createContext, useEffect, useReducer, useCallback } from 'react';

// // third-party
// import { Chance } from 'chance';

// // reducer - state management
// import { LOGIN, LOGOUT } from 'store/reducers/actions';
// import authReducer from 'store/reducers/auth';

// // project import
// import Loader from 'components/Loader';
// import axios from 'utils/axios';
// import ApiService from 'services/ApiService';
// import AuthService from 'services/AuthService';
// import jwt from 'jsonwebtoken';
// import { setupAutoLogout } from 'utils/AutoLogout';

// const chance = new Chance();

// // constant
// const initialState = {
//   isLoggedIn: false,
//   isInitialized: false,
//   user: null
// };

// const verifyToken = (serviceToken) => {
//   if (!serviceToken) {
//     return false;
//   }

//   try {
//     jwt.verify(serviceToken, process.env.REACT_APP_JWT_SECRET);
//     return true;
//   } catch (err) {
//     return false;
//   }

//   /**
//    * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
//    */
//   // return decoded.exp > Date.now() / 1000;
// };

// const setSession = (serviceToken) => {
//   if (serviceToken) {
//     localStorage.setItem('serviceToken', serviceToken);
//   } else {
//     localStorage.removeItem('serviceToken');
//     delete axios.defaults.headers.common.Authorization;
//   }
// };

// // ==============================|| JWT CONTEXT & PROVIDER ||============================== //

// const JWTContext = createContext(null);

// export const JWTProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(authReducer, initialState);

//   const logout = useCallback(async () => {
//     setSession(null);
//     dispatch({ type: LOGOUT });
//   }, []);

//   useEffect(() => {
//     const init = async () => {
//       try {
//         const serviceToken = window.localStorage.getItem('serviceToken');
//         if (serviceToken && verifyToken(serviceToken)) {
//           setSession(serviceToken);
//           const response = await ApiService.get({ path: '/auth/userInfo' });
//           const { user } = response.data;
//           dispatch({
//             type: LOGIN,
//             payload: {
//               isLoggedIn: true,
//               user
//             }
//           });
//         } else {
//           setSession(null);
//           dispatch({
//             type: LOGOUT
//           });
//         }
//       } catch (err) {
//         console.error(err);
//         dispatch({
//           type: LOGOUT
//         });
//       }
//     };

//     init();
//   }, []);

//   useEffect(() => {
//     if (state.isLoggedIn) {
//       const cleanupAutoLogout = setupAutoLogout(logout);
//       return () => cleanupAutoLogout();
//     }
//   }, [state.isLoggedIn, logout]);

//   const login = async (email, password) => {
//     const response = await AuthService.login(email, password);
//     const { serviceToken, user } = response.data;

//     setSession(serviceToken);
//     dispatch({
//       type: LOGIN,
//       payload: {
//         isLoggedIn: true,
//         user
//       }
//     });
//   };

import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback } from 'react';

// third-party
import { Chance } from 'chance';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import ApiService from 'services/ApiService';
import AuthService from 'services/AuthService';
import jwt from 'jsonwebtoken';
import { setupAutoLogout } from 'utils/AutoLogout';

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  try {
    jwt.verify(serviceToken, process.env.REACT_APP_JWT_SECRET);
    return true;
  } catch (err) {
    return false;
  }
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const clearAuthData = () => {
  localStorage.removeItem('serviceToken');
  localStorage.removeItem('user');
  sessionStorage.clear();
  delete axios.defaults.headers.common.Authorization;
  // Clear any other auth-related data if necessary
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const logout = useCallback(async () => {
    try {
      await AuthService.logout(); // Assuming you add a logout method to AuthService
    } catch (error) {
      console.error('Logout API call failed:', error);
    } finally {
      clearAuthData();
      dispatch({ type: LOGOUT });
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await ApiService.get({ path: '/auth/userInfo' });
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          clearAuthData();
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        clearAuthData();
        dispatch({
          type: LOGOUT
        });
      } finally {
        dispatch({ type: 'INITIALIZED' });
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (state.isLoggedIn) {
      const cleanupAutoLogout = setupAutoLogout(logout);
      return () => cleanupAutoLogout();
    }
  }, [state.isLoggedIn, logout]);

  const login = async (email, password) => {
    const response = await AuthService.login(email, password);
    const { serviceToken, user } = response.data;

    setSession(serviceToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  // const logout = async () => {
  //   setSession(null);
  //   dispatch({ type: LOGOUT });
  // };

  const resetPassword = async (email) => {
    const response = await AuthService.resetPassword(email);
    return response;
  };

  const updateProfile = async (objData) => {
    const response = await AuthService.getProfile(objData);
    return response;
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
